
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex'
import Button from '@/components/Button.vue'
export default defineComponent({
  name : 'Step4Survey',
  props:{
    price:{
      type: [String, Number]
    },
    text:[String]
  },
  components:{
    Button
  },
  computed:{
    ...mapGetters([
      'clientCreditsPlan'
    ])
  }
})
